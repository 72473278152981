<template>
  <modal-content
    ref="add-section-modal"
    v-bind="$attrs"
    v-on="$listeners"
    :max-width="480"
    name="sections"
    title="Add New Section"
    @close="closeModal"
  >
    <template #content>
      <div class="flex-1">
        <div class="flex flex-col mb-4">
          <span class="text-gray-6 inline-block py-2"
            >Please Enter Section Name</span
          >
          <Input
            placeholder="Section Name"
            bg="white"
            v-model="sectionName"
          >
          </Input>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end">
        <Button
          @click="handleAddNewSection"
          class="btn-primary"
          :disabled="!sectionName"
          text="Create new section "
        />
      </div>
    </template>
  </modal-content>
</template>

<script>
import Button from "@/components/button";
// import Loader from "../loader"
import modalContent from "@/components/modal-content";
import Input from "@/components/input";

export default {
  name: "add-section-modal",
  components: {
    // Loader,
    modalContent,
	Input,
	Button
  },
  data() {
    return {
      sectionName: "",
    };
  },
  props: {},
  mounted() {
    this.closeModal();
  },
  created(){
	this.sectionName = "";
  },
  methods: {
    closeModal() {
      this.$refs["add-section-modal"].hideModal();
    },
	showAddSectionModal(){
		this.sectionName = "";
		this.$refs["add-section-modal"].showModal();
	},
    async handleAddNewSection() {
      if (!this.sectionName) return this.$toast.error("Section Name required");
      this.$emit("addNewSection", this.sectionName);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  &__icon {
    fill: white;
    * {
      fill: white;
    }
  }
}
</style>